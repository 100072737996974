<template>
<div>
    <form-wizard color="#7367F0" :title="null" :subtitle="null" shape="square" finish-button-text="Submit" back-button-text="Previous" class="mb-3" @on-complete="formSubmitted">

        <!-- accoint details tab -->
        <tab-content title="Product" :before-change="validationForm">
            <validation-observer ref="accountRules" tag="form">
                <b-row>

                    <b-col cols="12" class="mb-2">
                        <h5 class="mb-0">
                            Product
                        </h5>
                        <small class="text-muted">
                            Enter Product Details.
                        </small>
                    </b-col>

                    <b-col md="6">
                        <b-form-group label="Slug" label-for="slug">
                            <validation-provider #default="{ errors }" name="slug" rules="required">
                                <b-form-input id="slug" v-model="form.slug" :state="errors.length > 0 ? false:null" placeholder="SLUG" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="6">
                        <b-form-group label="SKU" label-for="sku">
                            <validation-provider #default="{ errors }" name="sku" rules="required|integer">
                                <b-form-input id="sku" v-model="form.sku" type="number" :state="errors.length > 0 ? false:null" placeholder="SKU" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="6">
                        <validation-provider #default="{ errors }" name="Type" rules="required">
                            <b-form-group label="Type" label-for="type" :state="errors.length > 0 ? false:null">
                                <v-select id="type" v-model="form.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="types" :selectable="option => ! option.value.includes('select_value')" label="text" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider #default="{ errors }" name="Category" rules="required">
                            <b-form-group label="Category" label-for="category" :state="errors.length > 0 ? false:null">
                                <v-select id="category" v-model="form.category" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="categories" :selectable="option => ! option.value.includes('select_value')" label="text" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </validation-observer>
        </tab-content>

        <!-- personal details tab -->
        <tab-content title="Product Info" :before-change="validationFormInfo">
            <validation-observer ref="infoRules" tag="form">
                <b-row v-for="(product_info, index) in form.product_info" v-bind:data="product_info" v-bind:key="index" :style="{animation: 'slideInDown 0.5s'}">

                    <!-- Repeater starts here -->

                    <b-col md="2">
                        <validation-provider #default="{ errors }" name="Country" rules="required">
                            <b-form-group label="Country" label-for="country" :state="errors.length > 0 ? false:null">
                                <v-select id="country" v-model="product_info.country" :options="selectCountries" :selectable="option => ! option.value.includes('select_value')" label="name" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="2">
                        <validation-provider #default="{ errors }" name="Name" rules="required">
                            <b-form-group label="Name" label-for="name" :state="errors.length > 0 ? false:null">
                                <b-form-input color="success" label-placeholder="Name" v-model="product_info.name" name="name" type="text" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="2">
                        <validation-provider #default="{ errors }" name="Slug" rules="required">
                            <b-form-group label="Slug" label-for="slug" :state="errors.length > 0 ? false:null">
                                <b-form-input color="success" label-placeholder="Slug" v-model="product_info.slug" name="slug" type="text" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4" md="1">
                        <validation-provider #default="{ errors }" name="Description" rules="required">
                            <b-form-group label="Description" label-for="description" :state="errors.length > 0 ? false:null">
                                <b-form-textarea v-model="product_info.description" label="Discription" class="md:mt-10 mt-6 mb-0" rows="3" type="text" name="description" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col lg="2" md="3" class="mb-50">
                        <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="deleteProductInfo(index)">
                            <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                    </b-col>
                    <b-col cols="12">
                        <hr>
                    </b-col>
                    <!-- ends here -->
                </b-row>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" pill @click="addProductInfo">Add Product Info</b-button>

            </validation-observer>
        </tab-content>

        <!-- address  -->
        <tab-content title="Packages" :before-change="validationFormAddress">

            <validation-observer ref="addressRules" tag="form">
                <b-row v-for="(pack, index) in form.packages" v-bind:key="index" class="parent" :style="{animation: 'fadeIn 0.5s'}">

                    <!-- Repeater starts here -->

                    <b-col md="2">
                        <validation-provider #default="{ errors }" name="Package Name" rules="required">
                            <b-form-group label="Package Name" label-for="name" :state="errors.length > 0 ? false:null">
                                <b-form-input color="success" label-placeholder="Name" v-model="pack.name" name="package_name" type="text" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="1">
                        <validation-provider #default="{ errors }" name="QTY" rules="required">
                            <b-form-group label="QTY" label-for="package_qty" :state="errors.length > 0 ? false:null">
                                <b-form-input color="success" label-placeholder="qty" v-model="pack.qty" name="package_qty" type="number" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col lg="2" md="3" class="mb-50">
                        <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="deletePackage(index)">
                            <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                    </b-col>

                    <b-col lg="12" md="12">
                        <b-row v-for="(info, indexs) in form.packages[index].info" v-bind:key="indexs" class="child" :style="{animation: 'fadeIn 0.5s'}">

                            <!-- Repeater starts here -->

                            <b-col md="3">
                                <validation-provider #default="{ errors }" name="Country" rules="required">
                                    <b-form-group label="Country" label-for="country" :state="errors.length > 0 ? false:null">
                                        <v-select id="country" v-model="info.country" :options="selectCountries" :selectable="option => ! option.value.includes('select_value')" label="name" />
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col md="3">
                                <validation-provider #default="{ errors }" name="Real Price" rules="required">
                                    <b-form-group label="Real Price" label-for="package_real_price" :state="errors.length > 0 ? false:null">
                                        <b-form-input color="success" label-placeholder="package_real_price" v-model="info.real_price" name="package_slug" type="number" />
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col md="3">
                                <validation-provider #default="{ errors }" name="Sale Price" rules="required">
                                    <b-form-group label="Sale Price" label-for="package_slug" :state="errors.length > 0 ? false:null">
                                        <b-form-input color="success" label-placeholder="Sale Price" v-model="info.sale_price" name="package_sale_price" type="number" />
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col md="1">
                                <validation-provider #default="{ errors }" name="Delivery Price" rules="required">
                                    <b-form-group label="Delivery Price" label-for="package_slug" :state="errors.length > 0 ? false:null">
                                        <b-form-input color="success" label-placeholder="Delivery Price" v-model="info.delivery_price" name="package_delivery_price" type="number" />
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <!-- Remove Button -->
                            <b-col lg="2" md="3" class="mb-50">
                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="deletePackageInfo(index, indexs)">
                                    <feather-icon icon="XIcon" class="mr-25" />
                                </b-button>
                            </b-col>
                            <b-col cols="12">
                                <hr>
                            </b-col>
                            <!-- ends here -->
                        </b-row>
                    </b-col>

                    <b-col cols="12">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" pill @click="addPackageInfo(index)">Add Package Info</b-button>
                        <hr>
                    </b-col>
                    <!-- ends here -->
                </b-row>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" pill @click="addPackage">Add Package</b-button>
            </validation-observer>
        </tab-content>
        {{ this.form }}
    </form-wizard>

</div>
</template>

<script>
import {
    FormWizard,
    TabContent
} from 'vue-form-wizard'
import vSelect from 'vue-select'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
    mapState
} from "vuex";

import {
    BFormSelect,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BCardText,
    BIconHandIndex,
} from 'bootstrap-vue'
import {
    required
} from '@validations'
import {
    codeIcon
} from '../pages/code'
import Ripple from 'vue-ripple-directive'
import Api from "@/http/Api";
import {
    mapGetters
} from "vuex";

export default {
    components: {
        BFormSelect,
        BButton,
        BCardText,
        BFormTextarea,
        ValidationProvider,
        ValidationObserver,
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        vSelect,
        BFormInvalidFeedback,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters(["selectCountries"]),
    },

    mounted() {
        this.$store.dispatch("SELECT_COUNTRIES");
    },

    updated() {
        console.log(this.form.packages);
    },

    data() {
        return {
            form: {
                sku: '',
                slug: '',
                category: '',
                type: '',
                product_info: [],
                packages: [],
            },
            types: [{
                    text: 'product',
                    value: 'product'
                },
                {
                    text: 'accessory',
                    value: 'accessory'
                },
            ],
            categories: [{
                    text: 'Gadgets',
                    value: '1'
                },
                {
                    text: 'OutDoor',
                    value: '2'
                },
            ],
            required,
            codeIcon
        }
    },

    methods: {
        addProductInfo: function () {
            this.form.product_info.push({
                country: '',
                name: '',
                slug: '',
                description: ''
            });
        },

        deleteProductInfo: function (index) {
            this.form.product_info.splice(index, 1);
        },

        addPackage: function () {
            this.form.packages.push({
                name: '',
                qty: '',
                info: []
            });
        },

        deletePackage: function (index) {
            this.form.packages.splice(index, 1);
        },

        addPackageInfo: function (indexp) {
            this.form.packages[indexp].info.push({
                country: '',
                real_price: '',
                sale_price: '',
                delivery_price: ''
            })
        },

        deletePackageInfo: function (index, info) {
            this.form.packages[index].info.splice(info, 1);
        },

        formSubmitted() {
            return new Promise((resolve, reject) => {
                Api().post("/addProduct", this.$data.form).then(response => {
                        this.$router.push('/apps/e-commerce/shop');
                        this.$swal({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Product added!',
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        error.response.data.message.map((value, key) => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Error',
                                    text: value,
                                    icon: 'XIcon',
                                    variant: 'danger',
                                },
                            })
                        });
                    });
                resolve(true)
            })
        },
        validationForm() {
            return new Promise((resolve, reject) => {
                this.$refs.accountRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        validationFormInfo() {
            return new Promise((resolve, reject) => {
                this.$refs.infoRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        validationFormAddress() {
            return new Promise((resolve, reject) => {
                this.$refs.addressRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
    },
}
</script>

<style lang="scss">
.parent {
    padding: 30px;
    border: 1px solid #000;
    border-radius: 20px;
    margin: 20px;
}
</style>
